import axios from "axios";
import {
  getAuthStorage,
  removeAuthStorage,
  setAuthStorage,
} from "../helpers/functions/authStorage";
import isServer from "../helpers/functions/isServer";

const axiosService = axios.create({
  baseURL: `/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosService.interceptors.request.use(
  (request) => {
    const { token } = !isServer() && getAuthStorage();
    if (token) {
      request.headers.common = {
        Authorization: `Bearer ${token}`,
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const { token, refreshToken } = !isServer() && getAuthStorage();
    const { response } = error;
    const isUnauthorized = response?.status === 401;
    if (isUnauthorized) {
      getRefreshToken(refreshToken);
    }
    if (response?.status === 500) {
      return (window.location.href = "/500");
    }

    return Promise.reject(response.data);
  }
);

const getRefreshToken = (refreshToken) => {
  axiosService
    .post("/auth/refresh", { refreshToken })
    .then((res) => {
      const data = res.data;
      !isServer() && setAuthStorage({ ...data.data });
    })
    .catch(() => {
      if (!isServer()) {
        removeAuthStorage();
        return (window.location.href = "/signin");
      }
    });
};

const service = () => {
  return {
    get: async (url, config) => {
      return await axiosService.get(url, config);
    },
    post: async (url, payload) => {
      return await axiosService.post(url, payload);
    },
    patch: async (url, payload) => {
      return await axiosService.patch(url, payload);
    },
    delete: async (url, payload) => {
      return await axiosService.delete(url, { data: payload });
    },
  };
};

const api = service();

export default api;
