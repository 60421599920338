const size = {
  xs: '320px',
  sm: '375px',
  md: '428px',
  lg: '810px',
  xl: '1024px',
  xxl: '1280px',
  large: '1440px',
}

const breakpoints = {
  xs: `(max-width: ${size.xs})`, // iphone5
  sm: `(max-width: ${size.sm})`, // small-phone
  md: `(max-width: ${size.md})`, // phone
  lg: `(max-width: ${size.lg})`, // ipad
  xl: `(max-width: ${size.xl})`, // laptop
  xxl: `(max-width: ${size.xxl})`, // laptop
  large: `(max-width: ${size.large})`, // large-laptop
}

export default breakpoints
