const RESPONSE_MESSAGE = {
  DEFAULT: `Something went wrong. Don't worry please try again.`,
  ERROR: 'Error',
  WARNING: 'Warning',
  COMPLETED: 'Complete',
  CREATED_SUCCESS: 'has been created.',
  DELETED_SUCCESS: 'has been deleted.',
  UPDATED_SUCCESS: 'has been updated.',
  IMPORTED_SUCCESS: 'has been imported.',
  SUCCESSFULLY: 'has been successfully.',
  UNAUTHORIZED: 'Your credentials could not be verified. Please try again.',
  INCORRECT: 'Username or Password is incorrect.',
  ALREADY_REGISTERED: 'email addresses is already registered.',
  INVALID_CODE: 'Invitation code is invalid. Please try again later or contact support.',
  ACTIVATED_CODE: 'Your account has been successfully activated.',
  DUPLICATE_EMAIL: 'Please enter a different email.',
  'ERR-ROLE-IN-USE': 'Unable to delete This role is already in use.',
  'ERR-NOT-FOUND-DATA': 'Data not found.',
  'ERR-ANNOUNCEMENT-PIN-EXCEED-LIMIT': 'Announcement pin exceeds the limit.',
  'ERR-EMAIL-ALREADY': 'The email address is already registered.',
  EXEMPTIONS_DUPLICATE: 'This approval cannot be reversed and all buttons will then be disabled',
  'ERR-INVALID-EXEMPTION-OTP': 'Invalid OTP code Please try again or request a new OTP.',
}

export default RESPONSE_MESSAGE
