import React from 'react'
import { Button, notification, Divider, Space } from 'antd';
import { LineStyle } from './style'

const success = ({ className, message, description, onClick, onClose, ...config }) => {
  const title = 'Information'
  return notification.success({
    icon: <LineStyle/>,
    className,
    message: title,
    description,
    onClick,
    onClose,
    duration: 4,
    ...config,
  })
}

const error = ({ className, message, description, onClick, onClose, ...config }) => {
  const title = 'Information'
  return notification.error({
    icon: <LineStyle/>,
    className,
    message: title,
    description,
    onClick,
    onClose,
    duration: 5,
    ...config,
  })
}

export default { success, error }
