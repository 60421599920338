import clsx from 'clsx'
import PropTypes from 'prop-types'
import { default as NextLink } from 'next/link'
import { memo } from 'react'
import styled from 'styled-components'
import { TextStyle, TextLinkStyle, HeadingStyle, TitleStyle, LinkStyle, LinkDividerStyle } from './style.js'

export const Heading = memo(
  ({ className, children, color, level, lineHeight, textAlign, type, weight, ...props }) => {
    const classname = clsx({ [type]: type, [className]: className })
    return (
      <HeadingStyle
        className={classname}
        color={color}
        level={level}
        lineHeight={lineHeight}
        textAlign={textAlign}
        type={type}
        weight={weight}
        {...props}
      >
        {children}
      </HeadingStyle>
    )
  }
)

export const Title = memo(
  ({ className, children, color, level, lineHeight, textAlign, type, weight, onClick, ...props }) => {
    const classname = clsx({ [type]: type, [className]: className })
    return (
      <TitleStyle
        className={classname}
        color={color}
        level={level}
        lineHeight={lineHeight}
        textAlign={textAlign}
        type={type}
        weight={weight}
        onClick={onClick}
        {...props}
      >
        {children}
      </TitleStyle>
    )
  }
)

export const Text = styled.span`
  ${TextStyle}
`

export const TextLink = styled.span`
 ${TextLinkStyle}
`

export const Link = ({ className, children, color, href, level, lineHeight, textAlign, type, weight, onClick }) => {
  const classname = clsx({ [type]: type, [className]: className })
  const active = type === 'active'
  return (
    <NextLink href={href}>
      <LinkStyle
        className={classname}
        color={color}
        level={level}
        lineHeight={lineHeight}
        textAlign={textAlign}
        type={type}
        weight={weight}
        onClick={onClick}
      >
        {children}
        {active && <LinkDividerStyle />}
      </LinkStyle>
    </NextLink>
  )
}

const defaultProps = {
  type: 'primary',
  level: 'default',
  lineHeight: 'inherit',
  color: 'black',
  textAlign: 'left',
  weight: '400',
  children: null,
}

const propTypes = {
  classname: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'normal', 'active']),
  level: PropTypes.string,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  weight: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Heading.propTypes = propTypes
Title.propTypes = propTypes
Link.propTypes = { ...propTypes, href: PropTypes.string }

Text.defaultProps = defaultProps
TextLink.defaultProps = defaultProps
Heading.defaultProps = defaultProps
Title.defaultProps = defaultProps
Link.defaultProps = defaultProps
