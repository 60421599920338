import styled, { css } from 'styled-components'
import theme from '../../themes'
import breakpoints from '../../themes/breakpoints'

const getFontSize = (level) => {
  const font = theme.fontSize[level]

  if (font) return font

  const fontSize = {
    1: theme.fontSize.xxl,
    2: theme.fontSize.xl,
    3: theme.fontSize.large,
    4: theme.fontSize.medium,
    5: theme.fontSize.small,
    6: theme.fontSize.xSmall,
    default: theme.fontSize.default,
    small: theme.fontSize.tiny,
  }

  return fontSize[level] || theme.fontSize.default
}

export const TextStyle = css`
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.theme.fontFamily[props.type]};
  font-size: ${(props) => getFontSize(props.level)};
  color: ${(props) => props.theme.color[props.color]};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  text-transform: ${(props) => props.transform};
`
export const TextLinkStyle = css`
  font-weight: ${(props) => props.weight};
  font-family: ${(props) => props.theme.fontFamily[props.type]};
  font-size: ${(props) => getFontSize(props.level)};
  color: ${(props) => props.theme.color[props.color]};
  text-align: ${(props) => props.textAlign};
  text-decoration: underline;
  cursor: pointer;
`

export const HeadingStyle = styled.div`
  ${TextStyle}
  @media only screen and (${breakpoints.md}) {
    font-size: ${(props) => props.theme.fontSize.large};
  }
`

export const TitleStyle = styled.div`
  ${TextStyle}
  @media only screen and (${breakpoints.md}) {
    font-size: ${(props) => props.theme.fontSize.large};
  }
`

export const LinkStyle = styled.a`
  ${TextStyle}
  &:hover {
    color: ${(props) => props.theme.color.darkBlue};    
  }
`

export const LinkDividerStyle = styled.div`
  position: relative;
  border: 3px solid ${(props) => props.theme.color.darkBlue};
  border-radius: 8px;
`