export const setAuthStorage = ({ access_token, refresh_token }) => {
  if (access_token) localStorage.setItem('token', access_token)
  if (refresh_token) localStorage.setItem('refreshToken', refresh_token)
}

export const getAuthStorage = () => {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')
  return { token, refreshToken }
}

export const removeAuthStorage = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
}
